import * as React from 'react';
import * as yup from 'yup';
import {Form, Formik, FormikProps} from 'formik';
import {useRef, useState} from 'react';

import {ContentModal} from '../../ui/modals/ContentModal/ContentModal';
import {CookiesService} from '../../../client/cookies/cookies.service';
import {GaItemListName} from '../../../client/ga/ga-ecommerce.functions';
import {InputFormik} from '../../ui/forms/Input/InputFormik';
import {Item, OrderableItem} from '../item.class';
import {ItemToAdd} from '../../order-items/order-items.class';
import {ProductImage} from '../../ui/ProductImage/ProductImage';
import {SelectFormik} from '../../ui/forms/Select/SelectFormik';
import {useService} from '../../react/ServiceContext';
import {usStatesOptions} from '../../ui/forms/DropDownHelper';
import Button from '../../ui/Buttons/Button';

interface ShipRestrictionsFormValues {
    restrictionState: string;
    restrictionZip: string;
}

interface ShipRestrictionsModalProps {
    addToOrder: (itemsToAdd: ItemToAdd[], message?: string, increment?: boolean, useAddToOrderModal?: boolean) => void;
    gaItemListName: GaItemListName;
    onHide: () => void;
    orderableItem: OrderableItem | Item;
    show: boolean;
    unitsOrdered: number;
    useAddToOrderModal: boolean;
}

export const ShipRestrictionsModal = ({
    addToOrder,
    gaItemListName,
    onHide,
    orderableItem,
    show,
    unitsOrdered,
    useAddToOrderModal,
}: ShipRestrictionsModalProps) => {
    const [showCannotShipMessage, setShowCannotShipMessage] = useState(false);
    let initialState = '';
    let initialZip = '';
    const formRef = useRef<FormikProps<ShipRestrictionsFormValues>>();
    const formValidation = yup.object().shape({
        restrictionState: yup.string().required(`Please select a state`),
        restrictionZip: yup.string().required(`Please enter a Zip Code`),
    });
    const cookiesService: CookiesService = useService(`cookiesService`);
    const getGeoCookie = cookiesService?.getCookie('geo');
    const geo = getGeoCookie?.split('|');
    if (geo?.length >= 3) {
        initialState = geo[2];
        initialZip = geo[0];
    }

    /**
     * Confirms where item will be shipped before addToOrder
     * @param formValues
     */
    const confirmShipmentLocation = (formValues: ShipRestrictionsFormValues) => {
        if (orderableItem.excludeStArray.indexOf(formValues.restrictionState) >= 0) {
            setShowCannotShipMessage(true);
            return;
        }
        if (orderableItem.excludeStArray.indexOf(formValues.restrictionZip) >= 0) {
            setShowCannotShipMessage(true);
            return;
        }
        onHide();
        // Else invoke addToOrder()
        addToOrder(
            [{gaEcommerce: {item_list_name: gaItemListName}, item: orderableItem?.item, unitsOrdered}],
            null,
            true,
            useAddToOrderModal,
        );
    };

    /**
     * Template
     */
    return (
        <>
            <ContentModal
                title={
                    <>
                        {!showCannotShipMessage && (
                            <div className={'tw-text-[1.75rem] tw-font-normal tw-leading-[1.2] tw-mb-0'}>
                                Shipping Restrictions May Apply
                            </div>
                        )}
                        {showCannotShipMessage && (
                            <div className={'tw-text-[1.75rem] tw-font-normal tw-leading-[1.2] tw-mb-0'}>Unable to ship item</div>
                        )}
                    </>
                }
                show={show}
                onClose={onHide}
                footer={
                    <>
                        {!showCannotShipMessage && (
                            <Button
                                modal={true}
                                onClick={() => {
                                    formRef.current.submitForm().catch((formSubmitError) => {
                                        // eslint-disable-next-line no-console
                                        console.log(formSubmitError);
                                    });
                                }}
                                size="smToLg"
                                type="button"
                                variant="secondary"
                            >
                                Continue
                            </Button>
                        )}
                    </>
                }
            >
                <>
                    {!showCannotShipMessage && <div>This product cannot be shipped to some parts of the United States and Canada.</div>}
                    {showCannotShipMessage && (
                        <div>
                            This product cannot be shipped to your location due to regulations in your area. Please call us at
                            1-800-558-2808 to discuss alternative products or shipping methods.
                            <br />
                            <br />
                            This item has not been added to your order.
                        </div>
                    )}
                    <div className="tw-grid tw-grid-cols-1 md:tw-grid-cols-3 tw-items-center tw-my-4">
                        <div className="tw-w-full tw-relative">
                            <ProductImage
                                containerClass="tw-text-center"
                                altText={orderableItem?.description}
                                size={120}
                                src={orderableItem?.imagePath}
                            />
                        </div>
                        <div className="tw-w-full tw-relative tw-col-span-1 md:tw-col-span-2">
                            <div dangerouslySetInnerHTML={{__html: orderableItem?.description}} />
                            <div>
                                Pkg Qty {orderableItem?.pkgQty} | Order Qty {unitsOrdered}
                            </div>
                        </div>
                    </div>
                    {!showCannotShipMessage && (
                        <Formik
                            initialValues={{restrictionState: initialState, restrictionZip: initialZip}}
                            innerRef={formRef}
                            onSubmit={confirmShipmentLocation}
                            validationSchema={formValidation}
                        >
                            <Form noValidate={true}>
                                <div>Where will this product be shipped?</div>
                                <SelectFormik
                                    id="shipToState"
                                    label="State"
                                    className="maskPII"
                                    name="restrictionState"
                                    options={usStatesOptions()}
                                    required
                                />
                                <InputFormik
                                    className="maskPII"
                                    label="Zip Code"
                                    maxLength={5}
                                    name="restrictionZip"
                                    numbersOnly={true}
                                    required
                                />
                            </Form>
                        </Formik>
                    )}
                </>
            </ContentModal>
        </>
    );
};
