import * as React from 'react';
import {faExclamationCircle} from '@fortawesome/pro-solid-svg-icons';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';

import {DisplayCurrencyFormatted} from '../orders/display-currency-formatted/DisplayCurrencyFormatted';
import {Item} from '../items/item.class';
import {PriceObjectErrorType} from './price-object.class';
import {ToolTip} from '../ui/Tooltip/Tooltip';

interface PriceLineProps {
    basePrice?: boolean;
    className?: string;
    currency?: string;
    doNotShowEachPrice?: boolean;
    eachPrice?: number;
    error?: string;
    errorType?: PriceObjectErrorType;
    hideEachPrice?: boolean;
    login?: boolean;
    onLogon: () => void;
    onSale?: boolean;
    price?: number;
    showCurrencyTooltip?: boolean;
    unit?: string;
}

export const PriceLine = ({
    basePrice = false,
    className,
    currency,
    doNotShowEachPrice,
    eachPrice,
    error,
    errorType,
    hideEachPrice,
    login = false,
    onLogon,
    onSale = false,
    price,
    showCurrencyTooltip,
    unit,
}: PriceLineProps) => {
    // Error messaging template
    if (errorType === `CONVERT`) {
        return (
            <div className="ImpItem_priceLine">
                {error === `BLOCKED` ? (
                    <>Item Blocked</>
                ) : (
                    <>
                        Order Item: <a href={`/item/${error}`}>{Item.prettyItem(error)}</a>
                    </>
                )}
            </div>
        );
    } else if (errorType === `BLOCKED`) {
        return (
            <div
                className="ImpItem_priceLine"
                style={{lineHeight: `15px`, fontSize: `12px`}}
            >
                Item cannot be purchased
            </div>
        );
    } else if (error) {
        return <div className="ImpItem_priceLine">{error}</div>;
    }
    if (className) {
        className = ` ${className}`;
    }

    // Login template
    if (login) {
        return (
            <div className={`ImpItem_priceLine${className ? ` ${className}` : ''}`}>
                <a
                    onClick={(e) => {
                        e.preventDefault();
                        onLogon();
                    }}
                    href="/login"
                >
                    Log In To See Pricing
                </a>
            </div>
        );
    }

    // Standard template
    return (
        <>
            {onSale && <div className={`ImpItem_priceLine${className ? ` ${className}` : ''} tw-text-green-200`}>On Sale</div>}
            <div className={`ImpItem_priceLine${basePrice ? ' basePrice' : ''}${onSale ? ' tw-text-green-200' : ''}${className || ''}`}>
                <DisplayCurrencyFormatted amount={price} /> pkg
                {!doNotShowEachPrice &&
                    eachPrice !== 0 &&
                    (!hideEachPrice ? (
                        <span>
                            &nbsp;| <DisplayCurrencyFormatted amount={eachPrice} /> {unit?.toLowerCase()}
                        </span>
                    ) : (
                        <span className="tw-hidden xl:tw-inline">
                            | <DisplayCurrencyFormatted amount={eachPrice} /> {unit?.toLowerCase()}
                        </span>
                    ))}
                {currency && showCurrencyTooltip && (
                    <>
                        {' '}
                        <ToolTip
                            id="pricing-details"
                            title={`Prices are in ${currency}`}
                        >
                            <FontAwesomeIcon
                                icon={faExclamationCircle}
                                size="2x"
                            />
                        </ToolTip>
                    </>
                )}
            </div>
        </>
    );
};
